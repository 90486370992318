import { useState } from 'react';

import useFeatures from '../../hooks/useFeatures';
import Navigation from '../../components/Navigation';

import Tree from './components/Tree';
import Shrub from './components/Shrub';

const StartPage = () => {
    const [{ video, game }] = useFeatures();
    const [somethingOpen, setSomethingOpen] = useState(false);
    const onStateChange = (state: boolean) => {
        setSomethingOpen(state);
    };

    return (
        <div className="fixed w-full h-full forest-bg">
            <Navigation />

            <div className="fixed w-full h-full flex justify-center items-center">
                <div className="text-center">
                    {video && (
                        <Shrub
                            disabled={somethingOpen}
                            onStateChange={onStateChange}
                        />
                    )}

                    {game && (
                        <Tree
                            disabled={somethingOpen}
                            onStateChange={onStateChange}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default StartPage;
