import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import HomePage from './pages/Home';
import ErrorPage from './pages/Error';
import StartPage from './pages/epilepsy/Start';
import ExperienceSelectorPage from './pages/ExperienceSelector';
import LandscapeDetector from './components/LandscapeDetector';
import ParentalControlsPage from './pages/ParentalControls';
import LanguageSelectorPage from './pages/LanguageSelector';
import DoctorPage from './pages/doctor/DoctorPage';

const App = () => {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <DoctorPage />,
            errorElement: <ErrorPage />,
        },
        {
            path: '/start',
            element: <HomePage />,
        },
        {
            path: '/language',
            element: <LanguageSelectorPage />,
        },
        {
            path: '/experience',
            element: <ExperienceSelectorPage />,
        },
        {
            path: '/parental-controls',
            element: <ParentalControlsPage />,
        },
        {
            path: '/epilepsy',
            element: <StartPage />,
        },
    ]);

    return (
        <>
            <RouterProvider router={router} />
            <LandscapeDetector />
        </>
    );
};

export default App;
