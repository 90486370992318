import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';

const DoctorPage = () => {
    const { t } = useTranslation();

    const [formSubmitted, setFormSubmitted] = useState(false);

    const [formData, setFormData] = useState({
        firstName: '',
        email: '',
        condition: 'epilepsy',
    });

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Handle form submission here
        setFormSubmitted(true);
    };

    const qrCodeValue = `${window.location.origin}/start?name=${formData.firstName}`;

    return (
        <div className="container mx-auto p-4 h-full overflow-auto">
            <div className="max-w-md mx-auto border border-gray-400 rounded-xl shadow-lg p-4 bg-white bg-opacity-40 mt-4">
                <h1 className="text-2xl font-semibold mb-4">
                    {t('pages.doctor.header')}
                </h1>

                {formSubmitted ? (
                    <div className="flex items-center flex-col">
                        <QRCode value={qrCodeValue} />

                        <Link to={`/start?name=${formData.firstName}`}>
                            <button className="mt-4 py-4 px-16 border rounded-xl bg-blue-600 text-white transition-shadow hover:ring-2 ring-sky-700">
                                {t('pages.doctor.launch-button')}
                            </button>
                        </Link>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label
                                htmlFor="firstName"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('pages.doctor.form-labels.name')}
                            </label>
                            <input
                                required
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                className="mt-1 p-2 w-full border rounded-lg focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('pages.doctor.form-labels.email')}
                            </label>
                            <input
                                required
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="mt-1 p-2 w-full border rounded-lg focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="condition"
                                className="block text-sm font-medium text-gray-700"
                            >
                                {t('pages.doctor.form-labels.condition')}
                            </label>
                            <select
                                required
                                id="condition"
                                name="condition"
                                value={formData.condition}
                                onChange={handleChange}
                                className="mt-1 p-2 w-full border rounded-lg focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="epilepsy">
                                    {t('pages.doctor.conditions.epilepsy')}
                                </option>
                                <option value="asthma">
                                    {t('pages.doctor.conditions.asthma')}
                                </option>
                                <option value="type-1-diabetes">
                                    {t(
                                        'pages.doctor.conditions.type-1-diabetes'
                                    )}
                                </option>
                                <option value="eczema">
                                    {t('pages.doctor.conditions.eczema')}
                                </option>
                                <option value="chickenpox">
                                    {t('pages.doctor.conditions.chickenpox')}
                                </option>
                            </select>
                        </div>

                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="bg-blue-600 text-white p-2 rounded-lg "
                            >
                                Create
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default DoctorPage;
