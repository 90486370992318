import cx from 'classnames';
import useSound from 'use-sound';
import { FC, useState } from 'react';
import { createPortal } from 'react-dom';
import { getI18n, useTranslation } from 'react-i18next';

import tree from '../../../assets/bush_child_mid.png';
import { useClickOutside } from '../../../hooks/useClickOutside';

type Props = {
    disabled: boolean;
    onStateChange: (state: boolean) => void;
};

const Tree: FC<Props> = ({ disabled, onStateChange }) => {
    const { resolvedLanguage } = getI18n();

    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [playSound, { stop: stopSound }] = useSound(
        resolvedLanguage === 'en'
            ? `${process.env.PUBLIC_URL}/assets/sounds/mri_game_english.mp3`
            : `${process.env.PUBLIC_URL}/assets/sounds/mri_game_german.mp3`,
        {
            interrupt: true,
        }
    );

    const ref = useClickOutside<HTMLButtonElement>(() => {
        if (isSelected) {
            stopSound();
            setIsSelected(false);
        }
    });

    const onClick = () => {
        if (isSelected) {
            stopSound();
            changeModalState(true)();
        } else {
            playSound();
            setIsSelected(true);
        }
    };

    const changeModalState = (state: boolean) => () => {
        onStateChange(state);
        setIsOpen(state);
    };

    return (
        <>
            <button
                ref={ref}
                disabled={disabled}
                onClick={onClick}
                style={{ aspectRatio: '1/1' }}
                className={cx(
                    'fixed right-[20%] bottom-[35%] w-[20%] transition-all',
                    {
                        'scale-110': isSelected,
                    }
                )}
            >
                <img
                    src={tree}
                    rel="preload"
                    alt={t('pages.epilepsy.start.tree-alt') || ''}
                />
            </button>

            {isOpen &&
                createPortal(
                    <>
                        <div className="absolute w-full h-full bg-white bg-opacity-80 flex justify-center items-center">
                            <iframe
                                src="/game"
                                title="Game"
                                width={
                                    window.innerHeight < 825
                                        ? window.innerHeight * 1.455
                                        : 1200
                                }
                                height={
                                    window.innerHeight < 825
                                        ? window.innerHeight
                                        : 825
                                }
                            />
                        </div>

                        <button
                            onClick={changeModalState(false)}
                            className="absolute text-3xl top-0 right-4 text-black"
                        >
                            x
                        </button>
                    </>,
                    document.body
                )}
        </>
    );
};

export default Tree;
