import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const getOrientation = () =>
    window.screen?.orientation?.type ?? 'landscape-primary';

const LandscapeDetector = () => {
    const { t } = useTranslation();
    const [isLandscape, setIsLandscape] = useState(
        getOrientation() === 'landscape-primary' ||
            getOrientation() === 'landscape-secondary'
    );

    useEffect(() => {
        const onOrientationChange = (event: any) => {
            const angle = event.target.screen?.orientation?.angle ?? 90;

            if (Math.abs(angle) === 90) {
                setIsLandscape(true);
            } else {
                setIsLandscape(false);
            }
        };

        window.addEventListener('orientationchange', onOrientationChange);

        return () => {
            window.removeEventListener(
                'orientationchange',
                onOrientationChange
            );
        };
    }, []);

    return isLandscape ? null : (
        <div className="fixed w-full h-full z-20 bg-black text-white flex text-center items-center justify-center">
            {t('global.use-landscape')}
        </div>
    );
};

export default LandscapeDetector;
