import { useTranslation } from 'react-i18next';

import Navigation from '../components/Navigation';
import LinkWithQuery from '../components/LinkWithQuery';

import parent from '../assets/parent.svg';
import child from '../assets/child.svg';
import { useSearchParams } from 'react-router-dom';

const ExperienceSelectorPage = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const name = searchParams.get('name') ?? 'Thomas';

    return (
        <div className="fixed w-full h-full">
            <Navigation />

            <div className="fixed w-full h-full flex justify-center items-center">
                <div className="flex justify-between text-center -mt-16 md:-mt-36">
                    <LinkWithQuery to="/parental-controls">
                        <button className="md:w-auto w-56 mr-8 border border-gray-400 rounded-xl shadow-lg p-4 hover:ring-2 ring-sky-700 bg-white bg-opacity-40 hover:bg-opacity-60 transition-all">
                            <img
                                src={parent}
                                rel="preload"
                                alt="Parental Controls"
                                className="h-32 w-32 md:h-64 md:w-64 m-auto"
                            />

                            <h3 className="text-lg md:text-xl mt-8">
                                {t('pages.experience.adult-button', {
                                    name,
                                })}
                            </h3>
                        </button>
                    </LinkWithQuery>

                    <LinkWithQuery to="/epilepsy">
                        <button className="md:w-auto w-56 border border-gray-400 rounded-xl shadow-lg p-4 hover:ring-2 ring-sky-700 bg-white bg-opacity-40 hover:bg-opacity-60 transition-all">
                            <img
                                src={child}
                                rel="preload"
                                alt="Child Experience"
                                className="h-32 w-32 md:h-64 md:w-64 m-auto"
                            />

                            <h3 className="text-lg md:text-xl mt-8">
                                {t('pages.experience.child-button', {
                                    name,
                                })}
                            </h3>
                        </button>
                    </LinkWithQuery>
                </div>
            </div>
        </div>
    );
};

export default ExperienceSelectorPage;
