import { useTranslation } from 'react-i18next';

import { Switch } from '../components/Switch';
import useFeatures from '../hooks/useFeatures';
import Navigation from '../components/Navigation';

const ParentalControlsPage = () => {
    const { t } = useTranslation();
    const [features, toggleFeature] = useFeatures();

    return (
        <div className="fixed w-full h-full">
            <Navigation />

            <div className="fixed w-full h-full flex justify-center items-center">
                <div className="-mt-16 md:-mt-36 border p-4 rounded-xl shadow-md mx-4 w-full md:w-96 bg-white bg-opacity-40">
                    <h1 className="text-2xl font-semibold text-center">
                        {t('pages.parental-controls.title')}
                    </h1>

                    <div className="mt-8">
                        <div className="flex justify-between mt-4">
                            <label htmlFor="video-state">
                                {t('pages.parental-controls.video')}
                            </label>

                            <Switch
                                id="video-state"
                                checked={features.video}
                                onCheckedChange={() => toggleFeature('video')}
                            />
                        </div>

                        <div className="flex justify-between mt-4">
                            <label htmlFor="game-state">
                                {t('pages.parental-controls.game')}
                            </label>

                            <Switch
                                id="game-state"
                                checked={features.game}
                                onCheckedChange={() => toggleFeature('game')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParentalControlsPage;
