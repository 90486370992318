import { useTranslation } from 'react-i18next';
import { useRouteError, Link } from 'react-router-dom';

const ErrorPage = () => {
    const error = useRouteError();
    const { t } = useTranslation();

    console.error(error);

    return (
        <div className="fixed w-full h-full flex justify-center items-center">
            <div className="text-center">
                <h1 className="text-3xl font-semibold">
                    {t('pages.error.title')}
                </h1>
                <h2 className="text-xl font-light mt-2">
                    {t('pages.error.sub-title')}
                </h2>

                <Link
                    to="/"
                    className="block mt-4 hover:underline underline-offset-2 font-semibold text-sky-600"
                >
                    {t('pages.error.back-button')}
                </Link>
            </div>
        </div>
    );
};

export default ErrorPage;
