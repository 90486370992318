import cx from 'classnames';
import i18n from '../i18n/i18n';

import Navigation from '../components/Navigation';
import LinkWithQuery from '../components/LinkWithQuery';

const LanguageSelectorPage = () => {
    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    const languages = [
        { code: 'en', name: 'English', flag: 'gb' },
        { code: 'de', name: 'Deutsch', flag: 'de' },
        { code: 'ar', name: 'عربي', flag: 'eg' },
        { code: 'ua', name: 'українська', flag: 'ua' },
    ];

    return (
        <div className="fixed w-full h-full">
            <Navigation />

            <div className="fixed w-full h-full flex justify-center items-center -mt-4">
                <div>
                    {languages.map(({ code, name, flag }, idx) => (
                        <LinkWithQuery
                            key={code}
                            to="/experience"
                            onClick={() => changeLanguage(code)}
                        >
                            <button
                                className={cx(
                                    'flex justify-between items-center w-56 border border-gray-400 rounded-xl shadow-lg p-4 hover:ring-2 ring-sky-700 bg-white bg-opacity-40 hover:bg-opacity-60 transition-all',
                                    { 'mt-2': idx > 0 }
                                )}
                            >
                                <img
                                    src={`https://flagcdn.com/w40/${flag}.png`}
                                    width="40"
                                    alt=""
                                    role="presentation"
                                />

                                <h3 className="text-lg md:text-xl flex-1 text-left ml-4">
                                    {name}
                                </h3>
                            </button>
                        </LinkWithQuery>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LanguageSelectorPage;
