import { useState } from 'react';

type Features = {
    video: boolean;
    game: boolean;
};

const safeGetFromLocalStore = (name: keyof Features) => {
    const item = localStorage.getItem(`hippo.features.${name}`);
    return item ? (item === 'true' ? true : false) : true;
};

const useFeatures = () => {
    const [features, setFeatures] = useState({
        video: safeGetFromLocalStore('video'),
        game: safeGetFromLocalStore('game'),
    });

    const toggleFeature = (name: keyof Features) => {
        if (features[name]) {
            localStorage.setItem(`hippo.features.${name}`, 'false');
            setFeatures({ ...features, [name]: false });
        } else {
            localStorage.removeItem(`hippo.features.${name}`);
            setFeatures({ ...features, [name]: true });
        }
    };

    return [features, toggleFeature] as const;
};

export default useFeatures;
