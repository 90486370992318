import { useNavigate } from 'react-router-dom';

import logo from '../assets/logo-v2.png';

const Navigation = () => {
    const navigate = useNavigate();

    return (
        <div className="flex justify-between items-center p-2 md:px-10 md:py-4">
            <button
                className="border-2 border-black text-black font-bold rounded-full px-2 md:p-2 md:px-4 text-center"
                onClick={() => navigate(-1)}
            >{`❮`}</button>

            <img
                src={logo}
                alt=""
                rel="preload"
                role="presentation"
                className="h-8 md:h-10"
            />
        </div>
    );
};

export default Navigation;
