import { useRef, useEffect } from 'react';

export const useClickOutside = <T extends HTMLElement>(cb: () => void) => {
    const ref = useRef<T>(null);

    useEffect(() => {
        const onClick = (event: MouseEvent | TouchEvent) => {
            if (!ref.current) return;

            if (!ref.current.contains(event.target as Node)) {
                cb();
            }
        };

        document.addEventListener('click', onClick);
        document.addEventListener('touchend', onClick);

        return () => {
            document.removeEventListener('click', onClick);
            document.removeEventListener('touchend', onClick);
        };
    }, [cb, ref]);

    return ref;
};
