import { FC } from 'react';
import { Link, useLocation, LinkProps } from 'react-router-dom';

const LinkWithQuery: FC<LinkProps> = ({ children, to, ...props }) => {
    const { search } = useLocation();

    return (
        <Link to={to + search} {...props}>
            {children}
        </Link>
    );
};

export default LinkWithQuery;
