import cx from 'classnames';
import useSound from 'use-sound';
import { FC, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation, getI18n } from 'react-i18next';

import shrub from '../../../assets/bush_child_front.png';
import { useClickOutside } from '../../../hooks/useClickOutside';

type Props = {
    disabled: boolean;
    onStateChange: (state: boolean) => void;
};

const Shrub: FC<Props> = ({ disabled, onStateChange }) => {
    const { resolvedLanguage } = getI18n();

    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [playSound, { stop: stopSound }] = useSound(
        resolvedLanguage === 'en'
            ? `${process.env.PUBLIC_URL}/assets/sounds/epilepsy_video_english.mp3`
            : `${process.env.PUBLIC_URL}/assets/sounds/epilepsy_video_german.mp3`,
        {
            interrupt: true,
        }
    );

    const ref = useClickOutside<HTMLButtonElement>(() => {
        if (isSelected) {
            setIsSelected(false);
            stopSound();
        }
    });

    const onClick = () => {
        if (isSelected) {
            stopSound();
            changeModalState(true)();
        } else {
            playSound();
            setIsSelected(true);
        }
    };

    const changeModalState = (state: boolean) => () => {
        onStateChange(state);
        setIsOpen(state);
    };

    return (
        <>
            <button
                ref={ref}
                disabled={disabled}
                onClick={onClick}
                style={{ aspectRatio: '1/1' }}
                className={cx(
                    'fixed left-[20%] bottom-[5%] w-[20%] transition-all',
                    {
                        'scale-110': isSelected,
                    }
                )}
            >
                <img
                    src={shrub}
                    rel="preload"
                    alt={t('pages.epilepsy.start.shrub-alt') || ''}
                />
            </button>

            {isOpen &&
                createPortal(
                    <div className="bg-black text-white absolute top-4 left-4 right-4 bottom-4">
                        <div className="flex justify-center items-center h-full relative">
                            <iframe
                                src="https://player.vimeo.com/video/871174044?badge=0&amp;autopause=0&amp;autoplay=1&amp;controls=1&amp;player_id=0&amp;app_id=58479"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                                title="HippoCare Epilepsy"
                            />

                            <button
                                onClick={changeModalState(false)}
                                className="absolute text-3xl top-0 right-4"
                            >
                                x
                            </button>
                        </div>
                    </div>,
                    document.body
                )}
        </>
    );
};

export default Shrub;
